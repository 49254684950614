import Card from '../../components/card/Card';
import styles from './About.module.scss';

const About = () => {

  const text = "I'm a Software Engineer with 3 years experience. I have a focus in the frontend utilizing React, TypeScript, and SCSS. In my free time I love to play and develop video games and play MTG with my friends.";
  
  return (
    <div className={styles.about}>
      <Card 
      heading='Bryan Fay' 
      text='bfay9402@gmail.com'
      />
      <div className={styles.aboutContainer}>
        <h1>About Me</h1>
        <p>
          {text}
        </p>
      </div>
    </div>
  )
}

export default About