import styles from './Navbar.module.scss';

type NavbarProps = {
  children: string;
  data: string[];
}

const Navbar = (props: NavbarProps) => {
  const {
    children,
    data,
  } = props;

  const handleOnClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as HTMLAnchorElement;
    const id = target.getAttribute('href');
    const element = document.getElementById(String(id));
    element?.scrollIntoView();
  };

  return (
    <div className={styles.navbar}>
      <a href="https://www.bryanfaydev.com/"><h1>{children}</h1></a>
      <ul onClick={handleOnClick}>
        {data.map((item) => <li><a href={item.toLowerCase()}>{item}</a></li>)}
      </ul>
    </div>
  )
}

export default Navbar