import styles from './Header.module.scss';

const Header = () => {

  const  style = [styles.header];

  return (
    <div className={style.join(' ')}>
      <h1>Hi, I'm <span>Bryan Fay</span></h1>
      <h2>I'm a Software Engineer that thrives on challenges and architects innovative solutions.</h2>
    </div>
  )
}

export default Header